import { get, omit, isEmpty, isArray, cloneDeep, has } from "lodash";
import queryString from "query-string";
import { AnyAction } from "redux";
import { Action } from "redux-actions";
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import moment from "moment";

import { ApplicationState } from "../";
import callApi from "../../utils/callApi";
import { errorHandler } from "../../utils/errorHandler";
import { enqueueSnackbar } from "../notifications";
import { getPropertyById } from "../properties";
import {
  Subscription,
  SubscriptionState,
  SubscriptionStatuses,
  createSubscription,
} from "../subscriptions";
import { updateCampaignStatus } from "./";
import {
  addFlowCampaignStepper,
  addNewAd,
  archiveCampaign,
  createCampaign,
  deleteDraftCampaign,
  editCampaign,
  fetchAdAccountCampaigns,
  fetchAdAccounts,
  fetchAdIframeContainer,
  fetchAdvertMetaData,
  fetchCampaignMetadata,
  fetchCampaignModeById,
  fetchFacebookAds,
  fetchFacebookPages,
  fetchFacebookPixels,
  fetchFacebookAudiences,
  fetchFilteredData,
  fetchInstagramAccounts,
  fetchPendingCampaignSubscription,
  fetchProductCatalogue,
  getCampaignTemplates,
  getCredits,
  importFacebookCampaign,
  patchAdCreative,
  patchAdPostDefault,
  patchAgentData,
  setSelectedAccordion,
  setSelectedAdvert,
  setSelectedCampaign,
  updateFacebookCampaign,
  uploadAdCreative,
  setAddNewAdFlag,
  uploadCreativeVideoThumbnail,
  patchSelectedFlowCampaign,
  patchTargeting,
  patchTemplateRender,
  patchFieldsToMap,
  patchDashboardCampaignsQuery,
  connectClientFacebookPages,
  removeFbPage,
  getCampaignById,
  upsertPublishedCampaign,
  addToDashboardKpiCounts,
  getAdTemplateById,
  cancelCheckoutByTransactionId,
  setSeenCampaign,
  patchSocial,
  getCampaignByIdOrExternalRef,
  renderTemplate,
  saveDraftCampaignStep,
  campaignCheckout,
  saveCampaignStep,
  addNewAdToCampaign,
  getLinkedFacebookPagesByUserToken,
  linkSelectedClientFacebookPages,
  generateCreativeImage,
  getTargetingTemplateById,
  fetchGoogleAdAccounts,
  fetchCampaignDetails,
} from "./routines";
import {
  AdpostDefault,
  AgentData,
  FieldsToMap,
  SelectedFlowCampaign,
  Targeting,
  TemplateRender,
  CampaignSteps,
  DashboardCampaignsQuery,
  ConnectClientFacebookPagesPayload,
  FacebookPages,
  ConnectClientFacebookPagesResponse,
  RemoveFbPagePayload,
  RemoveFbPageResponse,
  DashboardKpiCounts,
  FlowBrandOrFuel,
  SocialState,
  RenderTemplatePayload,
  CampaignGoalTypes,
  SaveDraftCampaignStepPayload,
  CampaignStepsIds,
  FlowCampaign,
  CampaignLocalData,
  LinkSelectedClientFacebookPagesPayload,
  GetLinkedFacebookPagesByUserTokenPayload,
  GenerateCreativeImageRequestPayload,
  Entities,
} from "./types";
import { addGTMDataLayerWithRawData } from "../../utils/GoogleTagManager";
import {
  Organisation,
  OrganisationState,
  patchFbSocialAsset,
  patchSubOrgFbSocialAsset,
} from "../organisation";
import { AuthenticatedUser, patchUserFbSocial } from "../auth";

import { AxiosError } from "axios";
import { DEFAULT_LOCALE_SETTINGS } from '../../utils/defaultLocaleSettings';
import { getBillingCurrencyFromLocales } from '../../utils/socialMedia';
import { deriveCampaignStateFromDraft, deriveTemplateFields } from '../../utils/campaign.helper';
import { DURATION } from '../../utils/campaign.defaults';
import { Modes } from '../navigation';

// Get current role
function* getCurrentUser(): any {
  const { auth } = yield select((state: ApplicationState) => state);
  const roles = get(auth, "user.roles", []);
  const role = roles.includes("flow-admin") ? "admin/" : "";
  const landlordUser = get(auth, "user._id", undefined);
  return { role, landlordUser };
}

//#region social asset
function* handleFetchAdAccounts(): any {
  try {
    const { role } = yield call(getCurrentUser);
    const res = yield call(
      callApi,
      "get",
      `/${role}facebook/fetchAllAdAccounts`
    );
    yield put(
      fetchAdAccounts.success({
        adAccounts: res.data,
      })
    );
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
      yield put(fetchAdAccounts.failure(errorHandler((err as any).response)));
    } else {
      yield put(fetchAdAccounts.failure("An unknown error occured"));
    }
  } finally {
    yield put(fetchAdAccounts.fulfill());
  }
}

function* handleFetchFacebookPages(): any {
  try {
    const { role } = yield call(getCurrentUser);
    const res = yield call(
      callApi,
      "get",
      `/${role}facebook/fetchFacebookPages`
    );
    yield put(
      fetchFacebookPages.success({
        facebookPages: res.data,
      })
    );
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
      yield put(
        fetchFacebookPages.failure(errorHandler((err as any).response))
      );
    } else {
      yield put(fetchFacebookPages.failure("An unknown error occured"));
    }
  } finally {
    yield put(fetchFacebookPages.fulfill());
  }
}

function* handleFetchInstagramAccounts(action: AnyAction): any {
  try {
    const { role } = yield call(getCurrentUser);
    const selectedAdAccountId = action.payload;
    const res = yield call(
      callApi,
      "get",
      `/${role}facebook/fetchInstagramAccount/${selectedAdAccountId}`
    );
    yield put(
      fetchInstagramAccounts.success({
        instagramAccounts: res.data,
      })
    );
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
      yield put(
        fetchInstagramAccounts.failure(errorHandler((err as any).response))
      );
    } else {
      yield put(fetchInstagramAccounts.failure("An unknown error occured"));
    }
  } finally {
    yield put(fetchInstagramAccounts.fulfill());
  }
}

function* handleFetchProductCatalogue(): any {
  try {
    const { role } = yield call(getCurrentUser);
    const res = yield call(
      callApi,
      "get",
      `/${role}facebook/fetchProductCatalogue`
    );
    yield put(
      fetchProductCatalogue.success({
        productCatalogue: res.data,
      })
    );
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
      yield put(
        fetchProductCatalogue.failure(errorHandler((err as any).response))
      );
    } else {
      yield put(fetchProductCatalogue.failure("An unknown error occured"));
    }
  } finally {
    yield put(fetchProductCatalogue.fulfill());
  }
}

function* handleFetchFacebookPixels(action: AnyAction): any {
  try {
    const { role } = yield call(getCurrentUser);
    const selectedAdAccountId = action.payload;
    const res = yield call(
      callApi,
      "get",
      `/${role}facebook/fetchPixels/${selectedAdAccountId}`
    );
    yield put(
      fetchFacebookPixels.success({
        facebookPixels: res.data,
      })
    );
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
      yield put(
        fetchFacebookPixels.failure(errorHandler((err as any).response))
      );
    } else {
      yield put(fetchFacebookPixels.failure("An unknown error occured"));
    }
  } finally {
    yield put(fetchFacebookPixels.fulfill());
  }
}

function* handleFetchFacebookAudiences(action: AnyAction): any {
  try {
    const { role } = yield call(getCurrentUser);
    const selectedAdAccountId = action.payload;
    const res = yield call(
      callApi,
      "get",
      `/${role}facebook/fetch-custom-audiences-by-adaccountId`,
      { params: { adAccountId: selectedAdAccountId } }
    );
    yield put(
      fetchFacebookAudiences.success({
        facebookAudiences: res.data,
      })
    );
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
      yield put(
        fetchFacebookAudiences.failure(errorHandler((err as any).response))
      );
    } else {
      yield put(fetchFacebookAudiences.failure("An unknown error occured"));
    }
  } finally {
    yield put(fetchFacebookAudiences.fulfill());
  }
}

function* handleFetchGoogleAdAccounts(): any {
  try {
    const { role } = yield call(getCurrentUser);
    const res = yield call(
      callApi,
      "get",
      `/${role}google/getGoogleAdAccounts`
    );
    yield put(
      fetchGoogleAdAccounts.success({
        googleAdAccounts: res.data,
      })
    );
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
      yield put(fetchGoogleAdAccounts.failure(errorHandler((err as any).response)));
    } else {
      yield put(fetchGoogleAdAccounts.failure("An unknown error occured"));
    }
  } finally {
    yield put(fetchGoogleAdAccounts.fulfill());
  }
}

function* handleSetSelectedAdvert(action: AnyAction): any {
  try {
    yield put(setSelectedAdvert.success(action.payload));
  } catch (err) {
    yield put(setSelectedAdvert.failure("An unknown error occured"));
  }
}

function* handleFetchCampaignDetails(action: AnyAction): any {
  try {
    const { role } = yield call(getCurrentUser);
    const subscriptionId = get(action, "payload.subscriptionId", "");
    const skip = get(action, "payload.skip", 0);
    const limit = get(action, "payload.limit", 10);
    const res = yield call(
      callApi,
      "get",
      `/${role}subscriptions/${subscriptionId}/campaign-details?skip=${skip}&limit=${limit}`
    );
    yield put(
      fetchCampaignDetails.success({
        campaignDetails: res.data,
      })
    );
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
      yield put(
        fetchCampaignDetails.failure(errorHandler((err as any).response))
      );
    } else {
      yield put(fetchCampaignDetails.failure("An unknown error occured"));
    }
  } finally {
    yield put(fetchCampaignDetails.fulfill());
  }
}

function* handleFetchAdvertMetaData(action: AnyAction): any {
  try {
    const parameters = omit(action.payload, "targeting");
    const { role } = yield call(getCurrentUser);
    const res = yield call(
      callApi,
      "put",
      `/${role}facebook/adAccordionMetadata?${queryString.stringify(
        parameters
      )}`,
      { data: { targeting: get(action, "payload.targeting", undefined) } }
    );
    yield put(
      fetchAdvertMetaData.success({
        metaData: res.data,
      })
    );
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
      yield put(
        fetchAdvertMetaData.failure(errorHandler((err as any).response))
      );
    } else {
      yield put(fetchAdvertMetaData.failure("An unknown error occured"));
    }
  } finally {
    yield put(fetchAdvertMetaData.fulfill());
  }
}

function* handleFetchAdAccountCampaigns(action: AnyAction): any {
  try {
    const { role } = yield call(getCurrentUser);
    const organisationAdAccountId = action.payload;
    const res = yield call(
      callApi,
      "get",
      `/${role}facebook/fetchAllCampaigns/${organisationAdAccountId}`
    );
    yield put(
      fetchAdAccountCampaigns.success({
        adAccountCampaigns: res.data,
      })
    );
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
      yield put(
        fetchAdAccountCampaigns.failure(errorHandler((err as any).response))
      );
    } else {
      yield put(fetchAdAccountCampaigns.failure("An unknown error occured"));
    }
  } finally {
    yield put(fetchAdAccountCampaigns.fulfill());
  }
}

function* handleImportFacebookCampaign(action: AnyAction): any {
  try {
    const { importCampaignPayload } = action.payload;
    const { role } = yield call(getCurrentUser);
    yield put(importFacebookCampaign.request());
    const res = yield call(
      callApi,
      "put",
      `/${role}facebook/importfacebookcampaign`,
      { data: { importCampaignPayload } }
    );

    yield put(importFacebookCampaign.success(res.data));

    yield put(
      enqueueSnackbar({
        message: "Facebook Campaign was imported",
        options: {
          variant: "success",
        },
      })
    );
    return res.data;
  } catch (err) {
    if ((err as any).response) {
      yield put(
        importFacebookCampaign.failure(errorHandler((err as any).response))
      );
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: {
            variant: "error",
          },
        })
      );
    } else {
      yield put(importFacebookCampaign.failure("An unknown error occured."));
    }
  } finally {
    yield put(importFacebookCampaign.fulfill());
  }
}

function* handleFetchFilteredData(action: AnyAction): any {
  try {
    const { role } = yield call(getCurrentUser);

    const payload = omit(action.payload, ["isPagination"]);

    const res = yield call(
      callApi,
      "get",
      `/${role}facebook/fetchTopKpi?${queryString.stringify(payload)}`
    );

    const result = res.data;
    const jsonString = localStorage.getItem("checkout");

    if (!isEmpty(jsonString)) {
      const _campaignPayload = JSON.parse(String(jsonString));
      const gtmPublishStatus = localStorage.getItem("gtmPublishStatus");

      const campaignLocalId = get(_campaignPayload, "campaignLocalId");
      const objective = get(_campaignPayload, "objective");

      const brandAr = get(result, "flowbrand", []);
      const fuelAr = get(result, "flowfuel", []);
      const _campaigns = [...brandAr, ...fuelAr];

      const _campaign = _campaigns.find(
        (item) =>
          item.localCampaignId === campaignLocalId ||
          item.id === campaignLocalId
      );

      const isDraft = get(_campaign, "isDraft", true);

      if (!isEmpty(_campaign)) {
        if (!isDraft && gtmPublishStatus === "successful") {
          const activeSubscription = get(_campaign, "activeSubscription");
          const campaignType = get(_campaignPayload, "campaignType");

          const campaignPayload = {
            budget: get(_campaign, "budget", 0),
            ..._campaignPayload,
            campaignType,
            type: get(_campaign, "flowCampaignType"),
            billingFrequency: get(activeSubscription, "billingFrequency"),
            isRecurring: get(_campaign, "isRecurring", false),
            campaignLocalId,
            objective,
            flowPlan: get(_campaignPayload, "flowPlan"),
            name: get(_campaign, "name"),
            facebookCampaignId: get(_campaign, "id"),
          };

          const { auth, organisation } = yield select(
            (state: ApplicationState) => state
          );

          addGTMDataLayerWithRawData(
            { event: "publishCampaign", page: "campaigns" },
            {
              campaignType:
                campaignType === "FlowBrand" ? campaignType : "FlowFuel",
              organisationId: organisation,
              campaignId: campaignLocalId,
              step: undefined,
              user: get(auth, "user"),
              organisation: get(organisation, "organisation"),
              campaignData: {
                ...campaignPayload,
              },
            }
          );
          localStorage.removeItem("checkout");
          localStorage.removeItem("gtmPublishStatus");
        }
      }
    }

    yield put(
      fetchFilteredData.success({
        filteredData: res.data,
        flowCampaignType: get(action, "payload.flowCampaignType"),
      })
    );
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
    }
  } finally {
    yield put(fetchFilteredData.fulfill());
  }
}

function* handleUpdateFacebookCampaign(action: AnyAction): any {
  try {
    const { role } = yield call(getCurrentUser);
    yield put(updateFacebookCampaign.request());
    const res = yield call(
      callApi,
      "put",
      `/${role}facebook/updateFlowCampaigns?${queryString.stringify(
        action.payload.editCampaignPayload
      )}`
    );

    yield put(updateFacebookCampaign.success(res.data));

    yield put(
      enqueueSnackbar({
        message: "Facebook Campaign was updated",
        options: {
          variant: "success",
        },
      })
    );
    return res.data;
  } catch (err) {
    if ((err as any).response) {
      yield put(
        updateFacebookCampaign.failure(errorHandler((err as any).response))
      );
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: {
            variant: "error",
          },
        })
      );
    } else {
      yield put(updateFacebookCampaign.failure("An unknown error occured."));
    }
  } finally {
    yield put(updateFacebookCampaign.fulfill());
  }
}

function* handleArchiveCampaign(action: AnyAction): any {
  try {
    yield put(archiveCampaign.request());
    const { role } = yield call(getCurrentUser);

    const response = yield call(
      callApi,
      "put",
      `/${role}facebook/archiveCampaign?${queryString.stringify(
        action.payload
      )}`
    );
    yield put(archiveCampaign.success(response.data));
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
    }
  } finally {
    yield put(archiveCampaign.fulfill());
  }
}

function* handleDeleteDraftCampaign(action: AnyAction): any {
  try {
    yield put(deleteDraftCampaign.request());
    const { role } = yield call(getCurrentUser);

    const response = yield call(
      callApi,
      "delete",
      `/${role}facebook/deleteDraftCampaign?${queryString.stringify(
        action.payload
      )}`
    );
    yield put(deleteDraftCampaign.success(response.data));
    yield put(
      enqueueSnackbar({
        message: "Campaign was deleted",
        options: {
          variant: "success",
        },
      })
    );
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
    }
  } finally {
    yield put(archiveCampaign.fulfill());
  }
}

function* handleFacebookAds(action: AnyAction): any {
  try {
    const { role } = yield call(getCurrentUser);
    const facebooAds = yield call(
      callApi,
      "get",
      `/${role}facebook/fetchFacebookAds?${queryString.stringify(
        action.payload
      )}`
    );
    yield put(fetchFacebookAds.success(facebooAds.data));
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
    }
  } finally {
    yield put(fetchFacebookAds.fulfill());
  }
}

function* handleIframeContainer(action: AnyAction): any {
  try {
    const { role } = yield call(getCurrentUser);
    const iframeContainer = yield call(
      callApi,
      "get",
      `/${role}facebook/fetchAdIframeContainer?${queryString.stringify(
        action.payload
      )}`
    );
    yield put(fetchAdIframeContainer.success(iframeContainer));
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
    }
  } finally {
    yield put(fetchAdIframeContainer.fulfill());
  }
}

function* handleSetSelectedAccordion(action: AnyAction): any {
  try {
    yield put(setSelectedAccordion.request());
    yield put(setSelectedAccordion.success(action.payload));
  } catch (err) {
    console.error(err);
    if (err) {
      yield put(setSelectedAccordion.failure(errorHandler(err)));
    }
  } finally {
    yield put(setSelectedAccordion.fulfill());
  }
}

// Add flow campaign stepper
function* handleAddFlowCampaignStepper(action: AnyAction): any {
  try {
    yield put(addFlowCampaignStepper.request());
    yield put(addFlowCampaignStepper.success(action.payload));
  } catch (err) {
    console.error(err);
    if (err) {
      yield put(addFlowCampaignStepper.failure(errorHandler(err)));
    }
  } finally {
    yield put(addFlowCampaignStepper.fulfill());
  }
}

function* handleSetSelectedCampaign(action: AnyAction): any {
  try {
    yield put(setSelectedCampaign.request());
    yield put(setSelectedCampaign.success(action.payload));
  } catch (err) {
    console.error(err);
    if (err) {
      yield put(setSelectedCampaign.failure(errorHandler(err)));
    }
  } finally {
    yield put(setSelectedCampaign.fulfill());
  }
}

function* handleGetCredits(action: AnyAction): any {
  try {
    yield put(getCredits.request());
    const { role } = yield call(getCurrentUser);

    const response = yield call(
      callApi,
      "get",
      `/${role}creditiomanagement/total?${queryString.stringify(
        action.payload
      )}`
    );
    yield put(getCredits.success(response.data));
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
    }
  } finally {
    yield put(getCredits.fulfill());
  }
}

function* handleGetCampaignTemplates(action: AnyAction): any {
  try {
    yield put(getCampaignTemplates.request());
    const { role } = yield call(getCurrentUser);

    const response = yield call(
      callApi,
      "get",
      `/${role}ad-templates/group?${queryString.stringify(action.payload)}`
    );
    yield put(getCampaignTemplates.success(response.data));
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
    }
  } finally {
    yield put(getCampaignTemplates.fulfill());
  }
}
function* handleCreateCampaign(action: AnyAction): any {
  const { auth } = yield select((state: ApplicationState) => state);
  const userDisplayName = get(auth, "user.displayName", "");
  const campaignFlowStep = get(action, "payload.screenStep", "");
  try {
    yield put(createCampaign.request());

    const { role } = yield call(getCurrentUser);
    Object.assign(action.payload, {
      clientName: `${action.payload.clientName}( ${userDisplayName})`,
    });
    const response = yield call(
      callApi,
      "put",
      `/${role}facebook/saveCampaign`,
      { data: { ...action.payload } }
    );
    yield put(createCampaign.success({ ...response.data, campaignFlowStep }));
  } catch (err) {
    if ((err as any).response) {
      const errorMessage = errorHandler((err as any).response);
      if (campaignFlowStep === CampaignSteps.Summary) {
        yield put(createCampaign.failure(errorMessage));
      } else {
        yield put(
          enqueueSnackbar({
            message: errorMessage,
            options: { variant: "error" },
          })
        );
      }
    }
  } finally {
    yield put(createCampaign.fulfill());
  }
}

function* handleUpdateCampaignStatus(action: AnyAction): any {
  try {
    yield put(updateCampaignStatus.request());
    const { payload } = action;
    const { role } = yield call(getCurrentUser);
    const url = `/${role}facebook/editCampaign`;

    const response = yield call(callApi, "put", url, { data: payload });

    yield put(updateCampaignStatus.success(response.data));
  } catch (err) {
    if ((err as any).response) {
      yield put(
        updateCampaignStatus.failure(errorHandler((err as any).response))
      );
    } else {
      yield put(updateCampaignStatus.failure("An unknown error occured."));
    }
  } finally {
    yield put(updateCampaignStatus.fulfill());
  }
}

function* handleEditCampaign(action: AnyAction): any {
  try {
    const { payload } = action;
    const { role } = yield call(getCurrentUser);
    yield put(editCampaign.request());
    const response = yield call(
      callApi,
      "put",
      `/${role}facebook/editCampaign`,
      { data: { ...payload } }
    );

    yield put(editCampaign.success(response.data));
  } catch (err) {
    if ((err as any).response) {
      yield put(editCampaign.failure(errorHandler((err as any).response)));
    } else {
      yield put(editCampaign.failure("An unknown error occured."));
    }
  } finally {
    yield put(editCampaign.fulfill());
  }
}

function* handleFetchCampaignMetadata(action: AnyAction): any {
  try {
    yield put(fetchCampaignMetadata.request());
    const { role } = yield call(getCurrentUser);

    const organisation = get(action, "payload.organisation", undefined);
    const creativeTypesEnum = get(action, "payload.creativeTypesEnum", {});
    const agentDataSet = get(action, "payload.agentDataSet", []);
    const organisationId = get(organisation, "_id", "");
    const editMode = get(action, "payload.editMode", "");
    const facebookCampaignId = get(action, "payload.facebookCampaignId", "");
    const localeSettings = get(action, "payload.localeSettings", {});
    const response = yield call(
      callApi,
      "get",
      `/${role}facebook/fetchCampaignMetadata?${queryString.stringify({
        organisationId,
        editMode,
        facebookCampaignId,
      })}`
    );

    const propertyId = get(
      response,
      "data.propertyId",
      get(response, "data.campaignlocaldata.adsets[0].ads[0].propertyId", "")
    );
    if (propertyId) {
      yield put(getPropertyById({ propertyId }));
    }
    yield put(
      fetchCampaignMetadata.success({
        data: response.data,
        editMode,
        organisation,
        agentDataSet,
        creativeTypesEnum,
        localeSettings,
      })
    );
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
    }
  } finally {
    yield put(fetchCampaignMetadata.fulfill());
  }
}

function* handleUploadAdCreative(action: AnyAction): any {
  try {
    yield put(uploadAdCreative.request());
    const { role } = yield call(getCurrentUser);

    const organisation = get(action, "payload.organisation", undefined);
    const formData: FormData = get(action, "payload.formData");
    const creativeType = formData.get("creativeType");
    const creativeTypesEnum = get(action, "payload.creativeTypesEnum");
    const response = yield call(
      callApi,
      "post",
      `/${role}facebook/ad-creative?${queryString.stringify({ organisation })}`,
      { data: formData }
    );

    yield put(
      uploadAdCreative.success({
        data: response.data,
        creativeType,
        creativeTypesEnum,
      })
    );
  } catch (err) {
    if ((err as any).response) {
      const errorMessage = errorHandler((err as any).response);
      yield put(uploadAdCreative.failure(errorMessage));
      yield put(
        enqueueSnackbar({
          message: errorMessage,
          options: { variant: "error" },
        })
      );
    }
  } finally {
    yield put(uploadAdCreative.fulfill());
  }
}

function* handleUploadCreativeVideoThumbnail(action: AnyAction): any {
  try {
    yield put(uploadCreativeVideoThumbnail.request());
    const { role } = yield call(getCurrentUser);

    const organisation = get(action, "payload.organisation", undefined);
    const formData = get(action, "payload.formData");
    const response = yield call(
      callApi,
      "post",
      `/${role}facebook/ad-creative?${queryString.stringify({ organisation })}`,
      { data: formData }
    );

    yield put(uploadCreativeVideoThumbnail.success(response.data));
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
    }
  } finally {
    yield put(uploadCreativeVideoThumbnail.fulfill());
  }
}

function* handleFetchCampaignModeById(action: AnyAction): any {
  try {
    yield put(fetchCampaignModeById.request());
    const { organisationId, localCampaignId } = action.payload;

    const { role } = yield call(getCurrentUser);
    const response = yield call(
      callApi,
      "get",
      `/${role}facebook/fetchCampaignMode?${queryString.stringify({
        organisationId,
        localCampaignId,
      })}`
    );

    yield put(fetchCampaignModeById.success(response.data));
    return response.data;
  } catch (error) {
    if ((error as any).response) {
      yield put(
        fetchCampaignModeById.failure(errorHandler((error as any).response))
      );
    } else {
      yield put(fetchCampaignModeById.failure("An unknown error occured."));
    }
  } finally {
    yield put(fetchCampaignModeById.fulfill());
  }
}

function* handlePatchAdCreative(action: AnyAction): any {
  try {
    yield put(patchAdCreative.request());
    yield put(patchAdCreative.success(action.payload));
  } catch (err) {
    if (err) {
      console.error(err);
      yield put(patchAdCreative.failure(errorHandler(err)));
    }
  } finally {
    yield put(patchAdCreative.fulfill());
  }
}

function* handleFetchPendingCampaignSubscription(action: AnyAction): any {
  try {
    yield put(fetchPendingCampaignSubscription.request());
    const { role } = yield call(getCurrentUser);

    const organisationId = get(action, "payload.organisationId");
    const campaignId = get(action, "payload.campaignId");
    const user = get(action, "payload.agent");
    const status = SubscriptionStatuses.Pending;

    const response = yield call(
      callApi,
      "get",
      `/${role}subscriptions?${queryString.stringify({
        organisationId,
        campaignId,
        user,
        status,
      })}`
    );

    yield put(fetchPendingCampaignSubscription.success(response.data));
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
    }
  } finally {
    yield put(fetchPendingCampaignSubscription.fulfill());
  }
}

function* handleSetAddNewAdFlag(action: AnyAction): any {
  try {
    yield put(setAddNewAdFlag.request());
    yield put(setAddNewAdFlag.success(action.payload));
  } catch (err) {
    console.error(err);
    if (err) {
      yield put(setAddNewAdFlag.failure(errorHandler(err)));
    }
  } finally {
    yield put(setAddNewAdFlag.fulfill());
  }
}

function* handleAddNewAd(action: AnyAction): any {
  try {
    const { payload } = action;
    const { role } = yield call(getCurrentUser);
    yield put(addNewAd.request());
    const res = yield call(callApi, "put", `/${role}facebook/addNewAd`, {
      data: { ...payload },
    });

    yield put(addNewAd.success("New ad created successfully."));
    return res.data;
  } catch (err) {
    if ((err as any).response) {
      yield put(addNewAd.failure(errorHandler((err as any).response)));
    } else {
      yield put(addNewAd.failure("An unknown error occured."));
    }
  } finally {
    yield put(addNewAd.fulfill());
  }
}

function* handleAddNewAdWatcher(): any {
  yield takeEvery(addNewAd.TRIGGER, handleAddNewAd);
}

function* handleSetAddNewAdFlagWatcher(): any {
  yield takeEvery(setAddNewAdFlag.TRIGGER, handleSetAddNewAdFlag);
}

function* handlePatchAgentData(action: Action<Partial<AgentData>>): any {
  try {
    yield put(patchAgentData.request());
    yield put(patchAgentData.success(action.payload));
  } catch (err) {
    if (err) {
      console.error(err);
      yield put(patchAgentData.failure(errorHandler(err)));
    }
  } finally {
    yield put(patchAgentData.fulfill());
  }
}

function* handlePatchAdPostDefault(
  action: Action<Partial<AdpostDefault>>
): any {
  try {
    yield put(patchAdPostDefault.request());
    yield put(patchAdPostDefault.success(action.payload));
  } catch (err) {
    if (err) {
      console.error(err);
      yield put(patchAdPostDefault.failure(errorHandler(err)));
    }
  } finally {
    yield put(patchAdPostDefault.fulfill());
  }
}

function* handlePatchSelectedFlowCampaign(
  action: Action<Partial<SelectedFlowCampaign>>
): any {
  try {
    yield put(patchSelectedFlowCampaign.request());
    yield put(patchSelectedFlowCampaign.success(action.payload));
  } catch (err) {
    if (err) {
      console.error(err);
      yield put(patchSelectedFlowCampaign.failure(errorHandler(err)));
    }
  } finally {
    yield put(patchSelectedFlowCampaign.fulfill());
  }
}

function* handlePatchTargeting(action: Action<Partial<Targeting>>): any {
  try {
    yield put(patchTargeting.request());
    yield put(patchTargeting.success(action.payload));
  } catch (err) {
    if (err) {
      console.error(err);
      yield put(patchTargeting.failure(errorHandler(err)));
    }
  } finally {
    yield put(patchTargeting.fulfill());
  }
}

function* handlePatchTemplateRender(
  action: Action<Partial<TemplateRender>>
): any {
  try {
    yield put(patchTemplateRender.request());
    yield put(patchTemplateRender.success(action.payload));
  } catch (err) {
    if (err) {
      console.error(err);
      yield put(patchTemplateRender.failure(errorHandler(err)));
    }
  } finally {
    yield put(patchTemplateRender.fulfill());
  }
}

function* handlePatchFieldsToMap(action: Action<Partial<FieldsToMap>>): any {
  try {
    yield put(patchFieldsToMap.request());
    yield put(patchFieldsToMap.success(action.payload));
  } catch (err) {
    if (err) {
      console.error(err);
      yield put(patchFieldsToMap.failure(errorHandler(err)));
    }
  } finally {
    yield put(patchFieldsToMap.fulfill());
  }
}

function* handlePatchDashboardCampaignsQuery(
  action: Action<Partial<DashboardCampaignsQuery>>
): any {
  try {
    yield put(patchDashboardCampaignsQuery.request());
    yield put(patchDashboardCampaignsQuery.success(action.payload));
  } catch (err) {
    if (err) {
      console.error(err);
      yield put(patchDashboardCampaignsQuery.failure(errorHandler(err)));
    }
  } finally {
    yield put(patchDashboardCampaignsQuery.fulfill());
  }
}

function* handleConnectClientFacebookPages(
  action: Action<ConnectClientFacebookPagesPayload>
): any {
  try {
    yield put(connectClientFacebookPages.request());

    const { role } = yield call(getCurrentUser);
    const res = yield call(
      callApi,
      "put",
      `/${role}facebook/connectClientFacebookPagesIntegration`,
      {
        data: action.payload,
      }
    );

    const data: ConnectClientFacebookPagesResponse = get(res, "data") || {};

    const isMember = get(action, "payload.isMember", true);
    if (isMember) {
      // store on user state node
      const facebookPages: FacebookPages[] = isArray(data.memberSpecificPages)
        ? data.memberSpecificPages
        : [];
      yield put(patchUserFbSocial({ facebookPages }));
    } else {
      // store on organisation state node
      const facebookPages: FacebookPages[] = isArray(data.organisationPages)
        ? data.organisationPages
        : [];

      const orgState: OrganisationState = yield select(
        (state: ApplicationState) => state.organisation
      );
      if (
        orgState.subOrganisation &&
        orgState.subOrganisation._id === action.payload.organisationId
      ) {
        yield put(patchSubOrgFbSocialAsset({ facebookpage: facebookPages }));
      } else yield put(patchFbSocialAsset({ facebookpage: facebookPages }));
    }

    yield put(connectClientFacebookPages.success(data));

    const variant = "success";
    let message = "Facebook pages linked successfully";
    if (data.message) {
      message = data.message;
    }

    yield put(
      enqueueSnackbar({
        message,
        options: {
          variant,
        },
      })
    );
  } catch (err) {
    if (err) {
      console.error(err);
      yield put(connectClientFacebookPages.failure(errorHandler(err)));
    }
  } finally {
    yield put(connectClientFacebookPages.fulfill());
  }
}

function* handleRemoveFbPage(action: Action<RemoveFbPagePayload>): any {
  try {
    yield put(removeFbPage.request());

    const { role } = yield call(getCurrentUser);
    const res = yield call(
      callApi,
      "put",
      `/${role}facebook/manageFacebookPageDelink`,
      {
        data: action.payload,
      }
    );

    const data: RemoveFbPageResponse = get(res, "data") || {};

    if (!data.isDelinkedFromLocal) {
      return yield put(
        enqueueSnackbar({
          message: "Something went wrong",
          options: {
            variant: "error",
          },
        })
      );
    }

    const isMember = get(action, "payload.isMember", true);

    const orgState: OrganisationState = yield select(
      (state: ApplicationState) => state.organisation
    );

    let facebookPages: FacebookPages[] = [];
    if (isMember) {
      const user: AuthenticatedUser = yield select(
        (state: ApplicationState) => state.auth.user
      );
      facebookPages = cloneDeep(get(user, "social.facebookPages") || []);
    } else {
      const organisation = get(
        orgState,
        "subOrganisation",
        get(orgState, "organisation")
      );
      facebookPages = cloneDeep(
        get(
          organisation,
          "settings.socialAssetConfiguration.facebook.facebookpage"
        ) || []
      );
    }

    const idx = facebookPages.findIndex((page) => page.pageId === data.pageId);
    if (idx !== -1) facebookPages.splice(idx, 1);

    if (isMember) yield put(patchUserFbSocial({ facebookPages }));
    else {
      if (
        orgState.subOrganisation &&
        orgState.subOrganisation._id === action.payload.organisationId
      ) {
        yield put(patchSubOrgFbSocialAsset({ facebookpage: facebookPages }));
      } else yield put(patchFbSocialAsset({ facebookpage: facebookPages }));
    }

    yield put(removeFbPage.success(data));

    yield put(
      enqueueSnackbar({
        message: "Facebook page removed successfully",
        options: {
          variant: "success",
        },
      })
    );
  } catch (err) {
    if (err) {
      console.error(err);
      yield put(removeFbPage.failure(errorHandler(err)));
    }
  } finally {
    yield put(removeFbPage.fulfill());
  }
}

function* handleGetCampaignById(action: Action<string>): any {
  try {
    yield put(getCampaignById.request());

    const { role } = yield call(getCurrentUser);

    const campaignId = action.payload;

    const response = yield call(
      callApi,
      "get",
      `/${role}facebook/campaigns/${campaignId}?includeFbMetadata=true`
    );

    yield put(getCampaignById.success(response.data));
  } catch (err) {
    const error = err as AxiosError;
    const derivedError = errorHandler(error.response || error);

    yield put(getCampaignById.failure(derivedError));
  } finally {
    yield put(getCampaignById.fulfill());
  }
}

function* handleUpsertPublishedCampaign(action: Action<FlowBrandOrFuel>): any {
  try {
    yield put(upsertPublishedCampaign.request());
    yield put(upsertPublishedCampaign.success(action.payload));
  } catch (err) {
    console.error(err);
    if (err) {
      yield put(upsertPublishedCampaign.failure(errorHandler(err)));
    }
  } finally {
    yield put(upsertPublishedCampaign.fulfill());
  }
}

function* handleAddToDashboardKpiCounts(
  action: Action<DashboardKpiCounts>
): any {
  try {
    yield put(addToDashboardKpiCounts.request());
    yield put(addToDashboardKpiCounts.success(action.payload));
  } catch (err) {
    console.error(err);
    if (err) {
      yield put(addToDashboardKpiCounts.failure(errorHandler(err)));
    }
  } finally {
    yield put(addToDashboardKpiCounts.fulfill());
  }
}

function* handleGetAdTemplateById(action: Action<string>): any {
  try {
    const { role } = yield call(getCurrentUser);

    const response = yield call(
      callApi,
      "get",
      `/${role}ad-templates/${action.payload}`
    );
    yield put(getAdTemplateById.success(response.data));
  } catch (err) {
    const error = err as AxiosError;
    const derivedError = errorHandler(error.response || error);

    yield put(
      enqueueSnackbar({
        message: derivedError,
        options: { variant: "error" },
      })
    );
    yield put(getAdTemplateById.failure(derivedError));
  } finally {
    yield put(getAdTemplateById.fulfill());
  }
}

function* handleCancelCheckoutByTransactionId(action: Action<string>): any {
  try {
    const transactionId = action.payload;

    const response = yield call(
      callApi,
      "put",
      `/payments/${transactionId}/discard-checkout`
    );

    yield put(cancelCheckoutByTransactionId.success(response.data));
  } catch (err) {
    console.error(err);
    if (err) {
      yield put(cancelCheckoutByTransactionId.failure(errorHandler(err)));
    }
  } finally {
    yield put(cancelCheckoutByTransactionId.fulfill());
  }
}

function* handleSetSeenCampaign(action: Action<string>): any {
  try {
    yield put(setSeenCampaign.success(action.payload));
  } catch (err) {
    console.error(err);
    if (err) {
      yield put(setSeenCampaign.failure(errorHandler(err)));
    }
  } finally {
    yield put(setSeenCampaign.fulfill());
  }
}

function* handlePatchSocial(action: Action<Partial<SocialState>>): any {
  try {
    yield put(patchSocial.success(action.payload));
  } catch (err) {
    if (err) {
      console.error(err);
      yield put(patchSocial.failure(errorHandler(err)));
    }
  } finally {
    yield put(patchSocial.fulfill());
  }
}

function* handleGetCampaignByIdOrExternalRef(action: Action<string>): any {
  try {
    const { role } = yield call(getCurrentUser);

    const campaignIdOrExternalRef = action.payload;

    const response = yield call(
      callApi,
      "get",
      `/${role}facebook/campaigns?campaignIdOrExternalRef=${campaignIdOrExternalRef}`
    );

    const campaigns = response.data;
    if (isEmpty(campaigns)) throw new Error("Campaign not found");

    yield put(getCampaignByIdOrExternalRef.success(campaigns[0]));
  } catch (err) {
    const error = err as AxiosError;
    const derivedError = errorHandler(error.response || error);

    yield put(getCampaignByIdOrExternalRef.failure(derivedError));
  } finally {
    yield put(getCampaignByIdOrExternalRef.fulfill());
  }
}

function* handleRenderTemplate(action: Action<RenderTemplatePayload>): any {
  try {
    const { adTemplateId, modifications } = action.payload;

    const { role } = yield call(getCurrentUser);

    const response = yield call(
      callApi,
      "post",
      `/${role}ad-templates/${adTemplateId}/render`,
      {
        data: { modifications },
      }
    );

    const data = get(response, "data") || {};

    yield put(renderTemplate.success(data));
  } catch (err) {
    if (err) {
      console.error(err);
      yield put(renderTemplate.failure(errorHandler(err)));
    }
  } finally {
    yield put(renderTemplate.fulfill());
  }
}

function* handleSaveDraftCampaignStep(
  action: Action<SaveDraftCampaignStepPayload>
): any {
  const campaignFlowStep = get(action, "payload.screenStep", "");
  const nextStep = get(action, "payload.nextStep", "");

  try {
    const selectedFlowCampaign: SelectedFlowCampaign | undefined = yield select(
      ({ social }: ApplicationState) => social.selectedFlowCampaign
    );
    if (!selectedFlowCampaign) throw new Error("Something went wrong");

    const userDisplayName = yield select(({ auth }: ApplicationState) =>
      get(auth, "user.displayName", "")
    );

    const partialSelectedCampaign = omit(selectedFlowCampaign, [
      "selectedTemplate",
      "templateRender",
      "targeting",
      "adCreative",
      "customCreative",
      "budgetPerWeek",
      "weeks",
    ]);

    const adsets = [
      {
        targeting: selectedFlowCampaign.targeting,
        ads: [{ adCreative: selectedFlowCampaign.adCreative }],
      },
    ];

    const payload = {
      ...partialSelectedCampaign,
      adsets,
      screenStep: campaignFlowStep,
      nextStep,
      clientName: `${partialSelectedCampaign.clientName}( ${userDisplayName})`,
    };

    const { role } = yield call(getCurrentUser);

    const response = yield call(
      callApi,
      "put",
      `/${role}facebook/saveCampaign`,
      { data: payload }
    );

    yield put(
      saveDraftCampaignStep.success({ ...response.data, campaignFlowStep })
    );
  } catch (err) {
    if ((err as any).response) {
      const errorMessage = errorHandler((err as any).response);
      if (campaignFlowStep === CampaignSteps.Summary) {
        yield put(saveDraftCampaignStep.failure(errorMessage));
      } else {
        yield put(
          enqueueSnackbar({
            message: errorMessage,
            options: { variant: "error" },
          })
        );
      }
    }
  } finally {
    yield put(saveDraftCampaignStep.fulfill());
  }
}

function* handleCampaignCheckout(_: Action<any>): any {
  try {
    const selectedFlowCampaign: SelectedFlowCampaign | undefined = yield select(
      ({ social }: ApplicationState) => social.selectedFlowCampaign
    );
    if (!selectedFlowCampaign) throw new Error("Something went wrong");

    const isLinkOut = yield select(({ navigation }: ApplicationState) =>
      navigation.mode === Modes.LinkOut
    );

    const userDisplayName = yield select(({ auth }: ApplicationState) =>
      get(auth, "user.displayName", "")
    );
    const locales = yield select(
      ({ defaults }: ApplicationState) => defaults.locales
    );
    const selectedOrganisation: Organisation | undefined = yield select(
      ({ organisation }: ApplicationState) =>
        organisation.subOrganisation || organisation.organisation
    );
    const subscriptionState: SubscriptionState | undefined = yield select(
      ({ subscription }: ApplicationState) => subscription
    );

    const localeSettings = get(
      selectedOrganisation,
      "settings.locale",
      DEFAULT_LOCALE_SETTINGS
    );

    const billingCurrency = getBillingCurrencyFromLocales(
      locales,
      get(localeSettings, "country.code", "za")
    );

    const partialSelectedCampaign = omit(selectedFlowCampaign, [
      "selectedTemplate",
      "templateRender",
      "targeting",
      "adCreative",
      "customCreative",
      "budgetPerWeek",
      "weeks",
    ]);

    const adsets = [
      {
        targeting: selectedFlowCampaign.targeting,
        ads: [{ adCreative: selectedFlowCampaign.adCreative }],
      },
    ];

    const payload = {
      ...partialSelectedCampaign,
      adsets,
      screenStep: CampaignSteps.Summary,
      clientName: `${partialSelectedCampaign.clientName}( ${userDisplayName})`,
    };

    const checkoutPayload = {
      billingFrequency: get(subscriptionState, "billingFrequency"),
      flowPlanId: get(subscriptionState, "selectedFlowPlan._id"),
      isRecurring:
        selectedFlowCampaign.campaignGoalType === CampaignGoalTypes.FlowBrand,
      creditEntity: get(selectedFlowCampaign, "creditEntity", ""),
      campaignPayload: payload,
    };

    if (selectedFlowCampaign.campaignGoalType !== CampaignGoalTypes.FlowBrand) {
      const budgetPerWeek = get(
        selectedFlowCampaign,
        "budgetPerWeek.campaignBudget",
        get(selectedFlowCampaign, "budget")
      );

      if (!budgetPerWeek) {
        yield put(
          enqueueSnackbar({
            message: "Budget per week is required",
            options: {
              variant: "error",
            },
          })
        );
        return;
      }

      const adViews = get(subscriptionState, "adViews");
      const startDate = get(selectedFlowCampaign, "startDate");
      const weeks = get(selectedFlowCampaign, "weeks");
      const onceOffMetaData = {
        budgetPerWeek,
        billingCurrency,
        adViews,
        starting: startDate,
      };
      Object.assign(checkoutPayload.campaignPayload!, { onceOffMetaData });
      Object.assign(checkoutPayload, { numberOfWeeks: weeks, adViews });
    }

    const res = yield call(callApi, "post", `/campaign/checkout?isLinkOut=${isLinkOut}`, {
      data: checkoutPayload,
    });
    yield put(campaignCheckout.success({ checkout: res.data }));
  } catch (err) {
    if ((err as any).response) {
      const errorMessage = errorHandler((err as any).response);
      yield put(campaignCheckout.failure(errorMessage));
    }
  } finally {
    yield put(campaignCheckout.fulfill());
  }
}

function* handleSaveCampaignStep(action: Action<string>): any {
  const step = get(action, "payload", "");

  try {
    const selectedFlowCampaign: SelectedFlowCampaign | undefined = yield select(
      ({ social }: ApplicationState) => social.selectedFlowCampaign
    );
    if (!selectedFlowCampaign) throw new Error("Something went wrong");

    let payload = {
      organisationId: selectedFlowCampaign.organisationId,
      adAccountId: selectedFlowCampaign.adAccountId,
      facebookCampaignId: selectedFlowCampaign.facebookCampaignId,
    };

    switch (step) {
      case CampaignStepsIds.AdBuilder: {
        const isFlowBrand =
          selectedFlowCampaign.campaignGoalType === CampaignGoalTypes.FlowBrand;
        const objectiveKey = isFlowBrand
          ? "selectedBrandObjectiveType"
          : "selectedFlowFuelObjectiveType";

        Object.assign(payload, {
          ...selectedFlowCampaign.adCreative,
          editMode: CampaignSteps.AdCreative,
          screenStep: CampaignSteps.AdCreative,
          facebookPageId: selectedFlowCampaign.facebookPageId,
          instagramPageId: selectedFlowCampaign.instagramPageId,
          templateUrl: selectedFlowCampaign.adCreative.htmlContent,
          templateType: selectedFlowCampaign.adCreative.type,
          [objectiveKey]: selectedFlowCampaign[objectiveKey],
          [`${objectiveKey}Url`]: selectedFlowCampaign[`${objectiveKey}Url`],
        });

        break;
      }
      case CampaignStepsIds.Targeting: {
        Object.assign(payload, {
          ...selectedFlowCampaign.targeting,
          editMode: CampaignSteps.Targeting,
          screenStep: CampaignSteps.Targeting,
        });

        break;
      }
      default:
        break;
    }

    const { role } = yield call(getCurrentUser);

    const response = yield call(
      callApi,
      "put",
      `/${role}facebook/editCampaign`,
      { data: payload }
    );

    yield put(saveCampaignStep.success(response.data));
  } catch (err) {
    if ((err as any).response) {
      yield put(saveCampaignStep.failure(errorHandler((err as any).response)));
    } else {
      yield put(saveCampaignStep.failure("An unknown error occured."));
    }
  } finally {
    yield put(saveCampaignStep.fulfill());
  }
}

function* handleAddNewAdToCampaign(action: Action<any>): any {
  try {
    const selectedFlowCampaign: SelectedFlowCampaign | undefined = yield select(
      ({ social }: ApplicationState) => social.selectedFlowCampaign
    );
    if (!selectedFlowCampaign) throw new Error("Something went wrong");

    const isFlowBrand =
      selectedFlowCampaign.campaignGoalType === CampaignGoalTypes.FlowBrand;
    const objectiveKey = isFlowBrand
      ? "selectedBrandObjectiveType"
      : "selectedFlowFuelObjectiveType";

    let payload = {
      ...selectedFlowCampaign.adCreative,
      editMode: CampaignSteps.AdCreative,
      screenStep: CampaignSteps.AdCreative,
      facebookPageId: selectedFlowCampaign.facebookPageId,
      instagramPageId: selectedFlowCampaign.instagramPageId,
      templateUrl: selectedFlowCampaign.adCreative.htmlContent,
      templateType: selectedFlowCampaign.adCreative.type,
      [objectiveKey]: selectedFlowCampaign[objectiveKey],
      [`${objectiveKey}Url`]: selectedFlowCampaign[`${objectiveKey}Url`],
      organisationId: selectedFlowCampaign.organisationId,
      adAccountId: selectedFlowCampaign.adAccountId,
      facebookCampaignId: selectedFlowCampaign.facebookCampaignId,
    };

    const { role } = yield call(getCurrentUser);

    yield call(callApi, "put", `/${role}facebook/addNewAd`, { data: payload });

    yield put(addNewAdToCampaign.success("New ad created successfully."));
  } catch (err) {
    if ((err as any).response) {
      yield put(
        addNewAdToCampaign.failure(errorHandler((err as any).response))
      );
    } else {
      yield put(addNewAdToCampaign.failure("An unknown error occured."));
    }
  } finally {
    yield put(addNewAdToCampaign.fulfill());
  }
}

function* handleCreateSubscriptionSuccess(action: Action<Subscription>): any {
  try {
    yield put(patchSocial.success({ initializingCampaign: true }));

    const organisation = yield select(
      ({ organisation }: ApplicationState) =>
        organisation.subOrganisation || organisation.organisation
    );
    const owner = yield select(({ social }: ApplicationState) =>
      get(social, "selectedFlowCampaign.owner")
    );
    const days = yield select(({ social }: ApplicationState) =>
      get(social, "selectedFlowCampaign.days", DURATION.days)
    );
    const listing = yield select(
      ({ social }: ApplicationState) =>
        get(
          social,
          "selectedFlowCampaign.templateRender.agentData.selectedProperty"
        ) || null
    );

    const isRecurring = get(action.payload, "product.isRecurring", false);

    const campaignlocaldata: CampaignLocalData = get(
      action.payload,
      "metadata.facebook"
    );
    const adCreative = get(campaignlocaldata, "adsets[0].ads[0].adCreative");
    if (adCreative) {
      if (!isEmpty(adCreative.fieldsToMap)) {
        let orgFieldFound = false,
          agentFieldFound = false;
        for (const fieldToMap of adCreative.fieldsToMap) {
          if (fieldToMap.id === Entities.Organisation) orgFieldFound = true;
          if (fieldToMap.id === Entities.Agent) agentFieldFound = true;
        }
        if (!orgFieldFound)
          adCreative.fieldsToMap.unshift({
            id: Entities.Organisation,
            attribute: "innerText",
            value: get(campaignlocaldata, "organisationId"),
          });
        if (!agentFieldFound)
          adCreative.fieldsToMap.unshift({
            id: Entities.Agent,
            attribute: "innerText",
            value: get(adCreative, "agentId"),
          });

        const templateFields = deriveTemplateFields(adCreative.fieldsToMap);
        Object.assign(adCreative, { templateFields });
      }
      Object.assign(campaignlocaldata.adsets[0].ads[0], adCreative);
      delete (campaignlocaldata.adsets[0].ads[0] as any).adCreative;
    }

    const campaignDraft: Partial<FlowCampaign> = {
      campaignlocaldata: campaignlocaldata,
      flowcampaigntype: get(campaignlocaldata, "flowCampaignType"),
      objective: get(campaignlocaldata, "campaignObjective"),
      owner: owner || get(adCreative, "agentId"),
      campaignmode: "draft",
    };

    const selectedFlowCampaign: Partial<SelectedFlowCampaign> =
      deriveCampaignStateFromDraft(
        campaignDraft as any,
        organisation,
        listing,
        null,
        null
      );
    const startDateMoment = moment();
    const endDateMoment = startDateMoment.clone();

    if (isRecurring) {
      endDateMoment.add(1, "month");
    } else {
      endDateMoment.add(days, "days");
    }

    Object.assign(selectedFlowCampaign, {
      startDate: startDateMoment.toISOString(),
      endDate: endDateMoment.toISOString(),
    });

    yield put(patchSelectedFlowCampaign.success(selectedFlowCampaign));
  } catch (err) {
  } finally {
  }
}

function* handleCreateSubscriptionFulfill(action: Action<any>): any {
  yield put(patchSocial.success({ initializingCampaign: false }));
}

function* handleLinkedFacebookPagesByUserToken(
  action: Action<GetLinkedFacebookPagesByUserTokenPayload>
): any {
  try {
    const { role } = yield call(getCurrentUser);
    const res = yield call(
      callApi,
      "get",
      `/${role}facebook/fetchAuthenticatedLinkedFbPages?${queryString.stringify(
        action.payload
      )}`
    );
    yield put(getLinkedFacebookPagesByUserToken.success(res.data));
  } catch (err) {
    if ((err as any).response) {
      yield put(
        getLinkedFacebookPagesByUserToken.failure(
          errorHandler((err as any).response)
        )
      );
    } else {
      yield put(
        getLinkedFacebookPagesByUserToken.failure("An unknown error occured")
      );
    }
  } finally {
    yield put(getLinkedFacebookPagesByUserToken.fulfill());
  }
}

function* handleLinkSelectedClientFacebookPages(
  action: Action<LinkSelectedClientFacebookPagesPayload>
): any {
  try {
    yield put(linkSelectedClientFacebookPages.request());

    const { role } = yield call(getCurrentUser);
    const res = yield call(
      callApi,
      "put",
      `/${role}facebook/linkSelectedClientFacebookPages`,
      {
        data: action.payload,
      }
    );

    const data: ConnectClientFacebookPagesResponse = get(res, "data") || {};

    // store on organisation state node
    const facebookPages: FacebookPages[] = isArray(data.organisationPages)
      ? data.organisationPages
      : [];

    const orgState: OrganisationState = yield select(
      (state: ApplicationState) => state.organisation
    );
    if (
      orgState.subOrganisation &&
      orgState.subOrganisation._id === action.payload.organisationId
    ) {
      yield put(patchSubOrgFbSocialAsset({ facebookpage: facebookPages }));
    } else yield put(patchFbSocialAsset({ facebookpage: facebookPages }));

    yield put(linkSelectedClientFacebookPages.success(data));

    const totalPagesLinked = action.payload.selectedClientPages.length;
    const variant = "success";
    let message = `${totalPagesLinked} Facebook page${
      totalPagesLinked > 1 ? "s" : ""
    } linked successfully`;
    if (data.message) {
      message = data.message;
    }

    yield put(
      enqueueSnackbar({
        message,
        options: {
          variant,
        },
      })
    );
  } catch (err) {
    if ((err as any).response) {
      yield put(
        linkSelectedClientFacebookPages.failure(
          errorHandler((err as any).response)
        )
      );
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: {
            variant: "error",
          },
        })
      );
    } else {
      yield put(
        linkSelectedClientFacebookPages.failure("An unknown error occured")
      );
    }
  } finally {
    yield put(linkSelectedClientFacebookPages.fulfill());
  }
}

function* handleGenerateCreativeImage(
  action: Action<GenerateCreativeImageRequestPayload>
): any {
  try {
    const { role } = yield call(getCurrentUser);

    const response = yield call(
      callApi,
      "post",
      `/${role}ad-templates/generate-image`,
      {
        accept: "image/png, image/webp, application/json",
        responseType: "blob",
        data: action.payload,
      }
    );

    yield put(generateCreativeImage.success(response.data));
  } catch (err) {
    if (has(err as any, "response.data")) {
      const errorBlob = get(err, "response.data");
      const error = yield errorBlob.text();
      yield put(generateCreativeImage.failure(errorHandler(JSON.parse(error))));
    } else {
      yield put(generateCreativeImage.failure("An unknown error occured."));
    }
  } finally {
    yield put(generateCreativeImage.fulfill());
  }
}

function* handleGetTargetingTemplateById(action: Action<string>): any {
  try {
    const { role } = yield call(getCurrentUser);

    const response = yield call(
      callApi,
      "get",
      `/${role}master-campaigns/fetchTargetingTemplateById/${action.payload}`
    );
    yield put(getTargetingTemplateById.success(response.data));
  } catch (err) {
    const error = err as AxiosError;
    const derivedError = errorHandler(error.response || error);

    yield put(
      enqueueSnackbar({
        message: derivedError,
        options: { variant: "error" },
      })
    );
    yield put(getTargetingTemplateById.failure(derivedError));
  } finally {
    yield put(getTargetingTemplateById.fulfill());
  }
}

// Error handlers
function* handleCampaignError(action: AnyAction): any {
  yield put(
    enqueueSnackbar({
      message: action.payload,
      options: {
        variant: "error",
      },
    })
  );
}

function* campaignErrorWatcher(): any {
  yield takeEvery([updateCampaignStatus.FAILURE], handleCampaignError);
}

function* handleEditCampaignWatcher(): any {
  yield takeEvery(editCampaign.TRIGGER, handleEditCampaign);
}
function* handleAdAccountsWatcher(): any {
  yield takeEvery(fetchAdAccounts.TRIGGER, handleFetchAdAccounts);
}
function* handleFetchFacebookPagesWatcher(): any {
  yield takeEvery(fetchFacebookPages.TRIGGER, handleFetchFacebookPages);
}
function* handleInstagramAccountsWatcher(): any {
  yield takeEvery(fetchInstagramAccounts.TRIGGER, handleFetchInstagramAccounts);
}

function* handleProductCatalogueWatcher(): any {
  yield takeEvery(fetchProductCatalogue.TRIGGER, handleFetchProductCatalogue);
}

function* handleFacebookPixelsWatcher(): any {
  yield takeEvery(fetchFacebookPixels.TRIGGER, handleFetchFacebookPixels);
}

function* handleFacebookAudiencesWatcher(): any {
  yield takeEvery(fetchFacebookAudiences.TRIGGER, handleFetchFacebookAudiences);
}

function* handleGoogleAdAccountsWatcher(): any {
  yield takeEvery(fetchGoogleAdAccounts.TRIGGER, handleFetchGoogleAdAccounts);
}

function* handleFetchAdvertMetaDataWatcher(): any {
  yield takeEvery(fetchAdvertMetaData.TRIGGER, handleFetchAdvertMetaData);
}

function* handleFetchCampaignDetailsWatcher(): any {
  yield takeEvery(fetchCampaignDetails.TRIGGER, handleFetchCampaignDetails);
}

function* handleFetchFilteredDataWatcher(): any {
  yield takeEvery(fetchFilteredData.TRIGGER, handleFetchFilteredData);
}

function* handleAdAccountCampaignsWatcher(): any {
  yield takeEvery(
    fetchAdAccountCampaigns.TRIGGER,
    handleFetchAdAccountCampaigns
  );
}

function* handleImportFacebookCampaignWatcher(): any {
  yield takeEvery(importFacebookCampaign.TRIGGER, handleImportFacebookCampaign);
}

function* handleSetSelectedAdvertWatcher(): any {
  yield takeEvery(setSelectedAdvert.TRIGGER, handleSetSelectedAdvert);
}

function* handleUpdateFacebookCampaignWatcher(): any {
  yield takeEvery(updateFacebookCampaign.TRIGGER, handleUpdateFacebookCampaign);
}

function* handleFetchFacebookAdsWatcher(): any {
  yield takeEvery(fetchFacebookAds.TRIGGER, handleFacebookAds);
}

function* handleIframeContainerWatcher(): any {
  yield takeEvery(fetchAdIframeContainer.TRIGGER, handleIframeContainer);
}

function* handleSetSelectedAcccordionWatcher(): any {
  yield takeEvery(setSelectedAccordion.TRIGGER, handleSetSelectedAccordion);
}

function* handleArchiveCampaignWatcher(): any {
  yield takeEvery(archiveCampaign.TRIGGER, handleArchiveCampaign);
}

function* handleAddFlowCampaignStepperWatcher(): any {
  yield takeEvery(addFlowCampaignStepper.TRIGGER, handleAddFlowCampaignStepper);
}

function* handleSetSelectedCampaignWatcher(): any {
  yield takeEvery(setSelectedCampaign.TRIGGER, handleSetSelectedCampaign);
}

function* handleGetCreditsWatcher(): any {
  yield takeEvery(getCredits.TRIGGER, handleGetCredits);
}
function* handleGetCampaignTemplatesWatcher(): any {
  yield takeEvery(getCampaignTemplates.TRIGGER, handleGetCampaignTemplates);
}
function* handleCreateCampaignWatcher(): any {
  yield takeEvery(createCampaign.TRIGGER, handleCreateCampaign);
}
function* handleDeleteDraftCampaignWatcher(): any {
  yield takeEvery(deleteDraftCampaign.TRIGGER, handleDeleteDraftCampaign);
}

function* handleUpdateCampaignStatusWatcher(): any {
  yield takeEvery(updateCampaignStatus.TRIGGER, handleUpdateCampaignStatus);
}

function* handleFetchCampaignMetadataWatcher(): any {
  yield takeEvery(fetchCampaignMetadata.TRIGGER, handleFetchCampaignMetadata);
}

function* handleUploadAdCreativeWatcher(): any {
  yield takeEvery(uploadAdCreative.TRIGGER, handleUploadAdCreative);
}

function* handleUploadCreativeVideoThumbnailWatcher(): any {
  yield takeEvery(
    uploadCreativeVideoThumbnail.TRIGGER,
    handleUploadCreativeVideoThumbnail
  );
}

function* handleFetchCampaignModeByIdWatcher(): any {
  yield takeEvery(fetchCampaignModeById.TRIGGER, handleFetchCampaignModeById);
}

function* handlePatchAdCreativeWatcher(): any {
  yield takeEvery(patchAdCreative.TRIGGER, handlePatchAdCreative);
}

function* handleFetchPendingCampaignSubscriptionWatcher(): any {
  yield takeEvery(
    fetchPendingCampaignSubscription.TRIGGER,
    handleFetchPendingCampaignSubscription
  );
}

function* handlePatchAgentDataWatcher(): any {
  yield takeEvery(patchAgentData.TRIGGER, handlePatchAgentData);
}

function* handlePatchAdPostDefaultWatcher(): any {
  yield takeEvery(patchAdPostDefault.TRIGGER, handlePatchAdPostDefault);
}

function* handlePatchSelectedFlowCampaignWatcher(): any {
  yield takeEvery(
    patchSelectedFlowCampaign.TRIGGER,
    handlePatchSelectedFlowCampaign
  );
}

function* handlePatchTargetingWatcher(): any {
  yield takeEvery(patchTargeting.TRIGGER, handlePatchTargeting);
}

function* handlePatchTemplateRenderWatcher(): any {
  yield takeEvery(patchTemplateRender.TRIGGER, handlePatchTemplateRender);
}

function* handlePatchFieldsToMapWatcher(): any {
  yield takeEvery(patchFieldsToMap.TRIGGER, handlePatchFieldsToMap);
}

function* handlePatchDashboardCampaignsQueryWatcher(): any {
  yield takeEvery(
    patchDashboardCampaignsQuery.TRIGGER,
    handlePatchDashboardCampaignsQuery
  );
}

function* handleConnectClientFacebookPagesWatcher(): any {
  yield takeEvery(
    connectClientFacebookPages.TRIGGER,
    handleConnectClientFacebookPages
  );
}

function* handleRemoveFbPageWatcher(): any {
  yield takeEvery(removeFbPage.TRIGGER, handleRemoveFbPage);
}

function* getCampaignByIdWatcher(): any {
  yield takeEvery(getCampaignById.TRIGGER, handleGetCampaignById);
}

function* upsertPublishedCampaignWatcher(): any {
  yield takeEvery(
    upsertPublishedCampaign.TRIGGER,
    handleUpsertPublishedCampaign
  );
}

function* addToDashboardKpiCountsWatcher(): any {
  yield takeEvery(
    addToDashboardKpiCounts.TRIGGER,
    handleAddToDashboardKpiCounts
  );
}

function* getAdTemplateByIdWatcher(): any {
  yield takeEvery(getAdTemplateById.TRIGGER, handleGetAdTemplateById);
}

function* cancelCheckoutByTransactionIdWatcher(): any {
  yield takeEvery(
    cancelCheckoutByTransactionId.TRIGGER,
    handleCancelCheckoutByTransactionId
  );
}

function* setSeenCampaignWatcher(): any {
  yield takeEvery(setSeenCampaign.TRIGGER, handleSetSeenCampaign);
}

function* patchSocialWatcher(): any {
  yield takeEvery(patchSocial.TRIGGER, handlePatchSocial);
}

function* getCampaignByIdOrExternalRefWatcher(): any {
  yield takeEvery(
    getCampaignByIdOrExternalRef.TRIGGER,
    handleGetCampaignByIdOrExternalRef
  );
}

function* renderTemplateWatcher(): any {
  yield takeEvery(renderTemplate.TRIGGER, handleRenderTemplate);
}

function* saveDraftCampaignStepWatcher(): any {
  yield takeEvery(saveDraftCampaignStep.TRIGGER, handleSaveDraftCampaignStep);
}

function* campaignCheckoutWatcher(): any {
  yield takeEvery(campaignCheckout.TRIGGER, handleCampaignCheckout);
}

function* saveCampaignStepWatcher(): any {
  yield takeEvery(saveCampaignStep.TRIGGER, handleSaveCampaignStep);
}

function* addNewAdToCampaignWatcher(): any {
  yield takeEvery(addNewAdToCampaign.TRIGGER, handleAddNewAdToCampaign);
}
function* handleLinkedFacebookPagesByUserTokenWatcher(): any {
  yield takeEvery(
    getLinkedFacebookPagesByUserToken.TRIGGER,
    handleLinkedFacebookPagesByUserToken
  );
}

function* handleLinkSelectedClientFacebookPagesWatcher(): any {
  yield takeEvery(
    linkSelectedClientFacebookPages.TRIGGER,
    handleLinkSelectedClientFacebookPages
  );
}

function* generateCreativeImageWatcher(): any {
  yield takeEvery(generateCreativeImage.TRIGGER, handleGenerateCreativeImage);
}

function* createSubscriptionSuccessWatcher(): any {
  yield takeEvery(createSubscription.SUCCESS, handleCreateSubscriptionSuccess);
}

function* createSubscriptionFulfillWatcher(): any {
  yield takeEvery(createSubscription.FULFILL, handleCreateSubscriptionFulfill);
}

function* getTargetingTemplateByIdWatcher(): any {
  yield takeEvery(
    getTargetingTemplateById.TRIGGER,
    handleGetTargetingTemplateById
  );
}

//#region

export function* socialsSaga(): any {
  yield all([
    fork(handleAdAccountsWatcher),
    fork(handleFetchFacebookPagesWatcher),
    fork(handleInstagramAccountsWatcher),
    fork(handleProductCatalogueWatcher),
    fork(handleFacebookPixelsWatcher),
    fork(handleFetchAdvertMetaDataWatcher),
    fork(handleFacebookAudiencesWatcher),
    fork(handleGoogleAdAccountsWatcher),
    fork(handleAdAccountCampaignsWatcher),
    fork(handleImportFacebookCampaignWatcher),
    fork(handleFetchFilteredDataWatcher),
    fork(handleUpdateFacebookCampaignWatcher),
    fork(handleFetchFacebookAdsWatcher),
    fork(handleIframeContainerWatcher),
    fork(handleSetSelectedAdvertWatcher),
    fork(handleSetSelectedAcccordionWatcher),
    fork(handleArchiveCampaignWatcher),
    fork(handleAddFlowCampaignStepperWatcher),
    fork(handleSetSelectedCampaignWatcher),
    fork(handleGetCreditsWatcher),
    fork(handleGetCampaignTemplatesWatcher),
    fork(handleCreateCampaignWatcher),
    fork(handleDeleteDraftCampaignWatcher),
    fork(campaignErrorWatcher),
    fork(handleUpdateCampaignStatusWatcher),
    fork(handleEditCampaignWatcher),
    fork(handleFetchCampaignMetadataWatcher),
    fork(handleUploadAdCreativeWatcher),
    fork(handleUploadCreativeVideoThumbnailWatcher),
    fork(handleFetchCampaignModeByIdWatcher),
    fork(handlePatchAdCreativeWatcher),
    fork(handleFetchPendingCampaignSubscriptionWatcher),
    fork(handleAddNewAdWatcher),
    fork(handleSetAddNewAdFlagWatcher),
    fork(handlePatchAgentDataWatcher),
    fork(handlePatchAdPostDefaultWatcher),
    fork(handlePatchSelectedFlowCampaignWatcher),
    fork(handlePatchTargetingWatcher),
    fork(handlePatchTemplateRenderWatcher),
    fork(handlePatchFieldsToMapWatcher),
    fork(handlePatchDashboardCampaignsQueryWatcher),
    fork(handleConnectClientFacebookPagesWatcher),
    fork(handleRemoveFbPageWatcher),
    fork(getCampaignByIdWatcher),
    fork(upsertPublishedCampaignWatcher),
    fork(addToDashboardKpiCountsWatcher),
    fork(getAdTemplateByIdWatcher),
    fork(cancelCheckoutByTransactionIdWatcher),
    fork(setSeenCampaignWatcher),
    fork(patchSocialWatcher),
    fork(getCampaignByIdOrExternalRefWatcher),
    fork(renderTemplateWatcher),
    fork(saveDraftCampaignStepWatcher),
    fork(campaignCheckoutWatcher),
    fork(saveCampaignStepWatcher),
    fork(addNewAdToCampaignWatcher),
    fork(createSubscriptionSuccessWatcher),
    fork(createSubscriptionFulfillWatcher),
    fork(handleLinkedFacebookPagesByUserTokenWatcher),
    fork(handleLinkSelectedClientFacebookPagesWatcher),
    fork(generateCreativeImageWatcher),
    fork(getTargetingTemplateByIdWatcher),
    fork(handleFetchCampaignDetailsWatcher),
  ]);
}
